import parse, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";
import { ChevronDown } from "lucide-react";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import UILink from "@/components/ui/link";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
// FIXME cn drops some classes when merging
import { cn } from "@/lib/utils";

const variants = ["tip", "premium", "caution", "dd", "digging", "quotation", "summary"] as const;
type Variant = (typeof variants)[number];
const variantMap: Record<string, Variant> = {
	"bg-yellow": "tip",
	"bg-red": "caution",
};

const options: HTMLReactParserOptions = {
	replace: (node: any) => {
		const { attribs, name, children, data }: any = node as Element;
		if (!attribs) return;

		if (attribs?.class?.includes("sidebar")) {
			const idx = children.findIndex((child: any) => ["h1", "h2", "h3"].includes(child.name));
			const chevronIdx = children.findIndex((child: any) => child.name === "label");
			const title = children[idx];
			const id = title?.attribs?.id;

			const hasTitle = idx !== -1;
			const description = hasTitle ? children.slice(idx + 1) : children.slice(chevronIdx + 1);

			const getVariantFromId = (id: string) => {
				if (!id) return;

				const variant = variants.find(
					(variant) =>
						id.includes(`-${variant}-`) ||
						id.startsWith(`${variant}-`) ||
						id.endsWith(`-${variant}`)
				);

				return variant;
			};

			const getClassVariant = (classStr: string) => {
				const classes = classStr.split(" ");
				for (const className of classes) {
					if (variantMap[className]) {
						return variantMap[className];
					}
				}
				return "dd";
			};

			const variant = getVariantFromId(id) || getClassVariant(attribs?.class);

			if (!hasTitle) {
				return (
					<Alert variant={variant} className={cn("relative my-7 py-2", attribs?.class)}>
						<AlertDescription>{domToReact(description, options)}</AlertDescription>
					</Alert>
				);
			}

			return (
				<Collapsible className="group scroll-mt-19" id={id}>
					<Alert variant={variant} className={cn("relative my-7 !px-0 py-2", attribs?.class)}>
						<CollapsibleTrigger className="relative w-full pl-7 pr-9">
							<AlertTitle className="text-left" as={title?.name}>
								{title && domToReact((title as any)?.children)}
								<span className="absolute right-2 top-1/2 flex size-6 -translate-y-1/2 items-center justify-end md:right-4">
									<ChevronDown
										size={24}
										className="transition-transform duration-200 ease-in-out group-data-[state=open]:rotate-180"
									/>
								</span>
							</AlertTitle>
						</CollapsibleTrigger>
						<CollapsibleContent className="pl-7 pr-9">
							<AlertDescription>{domToReact(description, options)}</AlertDescription>
						</CollapsibleContent>
					</Alert>
				</Collapsible>
			);
		}

		if (attribs["data-tooltip"]) {
			const variant = attribs.class === "ref-link" ? "reference" : "overview";

			return (
				<Tooltip>
					<TooltipTrigger asChild>
						<span>
							<a id={attribs.id} href={attribs.href} className={attribs.class}>
								{domToReact(children, options)}
							</a>
						</span>
					</TooltipTrigger>
					<TooltipContent className="[@media(max-width:768px)]:hidden" variant={variant}>
						{parse(attribs["data-tooltip"], options)}
					</TooltipContent>
				</Tooltip>
			);
		}

		// TODO refactor code
		if (name === "table") {
			return (
				<div className="prose flex w-full max-w-none overflow-x-auto prose-a:text-examine-purple-400">
					<table className="box-content w-full">{domToReact(children, options)}</table>
				</div>
			);
		}
		if (name === "caption") {
			return <caption className="text-content py-2 text-base">{domToReact(children)}</caption>;
		}
		if (name === "th") {
			return (
				<th
					className={`not-prose whitespace-nowrap border-1 border-examine-purple-400 bg-primary p-3 text-center text-13 font-medium text-white shadow-table-head sm:whitespace-normal sm:px-0 sm:py-5 sm:text-base sm:font-normal ${attribs.class}`}
				>
					{domToReact(children, options)}
				</th>
			);
		}
		if (name === "td") {
			return (
				<td
					className={`border-1 border-gray-300 p-3 align-middle text-13 sm:p-5 sm:text-[15px] lg:text-lg lg:leading-9 ${attribs.class}`}
				>
					{domToReact(children, options)}
				</td>
			);
		}
		if (name === "tr") {
			return (
				<tr className={`odd:bg-extra-gray-500 even:bg-white ${attribs.class}`}>
					{domToReact(children, options)}
				</tr>
			);
		}
		if (name === "a") {
			const { href, class: className } = attribs;
			const isResearchBreakdownPage = /\/[^/]+\/[^/]+\/research/.test(href);

			return (
				<UILink
					href={href || ""}
					className={className}
					rel={isResearchBreakdownPage ? "nofollow" : undefined}
				>
					{domToReact(children)}
				</UILink>
			);
		}
	},
};

const parser = (html = "", opts = options) => {
	if (!html || typeof html != "string") return null;

	return parse(html, opts);
};

export default parser;
