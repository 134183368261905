"use client";

import { useSettings } from "@lib/hooks/use-settings";
import { getCookie } from "cookies-next";
import { usePathname } from "next/navigation";
import * as React from "react";

import { ClientOnly } from "@/components/client-only";
import { useFeatures } from "@/lib/hooks/use-features";
import { access as ACCESS, canAccess } from "@/lib/utils/index";

interface AccessCheckProps extends React.HTMLAttributes<HTMLElement> {
	feature?: (
		| "all"
		| "examine_database"
		| "study_summaries"
		| "supplement_guides"
		| "deep_dives_archive"
		| "examine_ai"
	)[];
	cta?: React.ReactNode;
	loggedIn?: boolean;
	featureCheck?: "all" | "any";
}

const AccessCheck = ({
	children,
	feature,
	cta,
	loggedIn,
	featureCheck = "all",
}: AccessCheckProps) => {
	const token = getCookie("token");
	const { data: userFeatures } = useFeatures();
	const [hasAccess, setHasAccess] = React.useState(false);
	const { settings } = useSettings();
	const pathname = usePathname();

	React.useEffect(() => {
		if (settings?.free_access?.length && settings?.free_access.includes(pathname)) {
			setHasAccess(true);
		} else {
			if (Array.isArray(feature)) {
				let access = true;
				feature.forEach((item) => {
					if (!canAccess(ACCESS[item], userFeatures)) {
						access = false;
					}
				});
				setHasAccess(access);

				if (featureCheck === "any") {
					access = false;
					feature.forEach((item) => {
						if (canAccess(ACCESS[item], userFeatures)) {
							access = true;
						}
					});
					setHasAccess(access);
				}
			}
		}
	}, [feature, featureCheck, userFeatures]);

	if (loggedIn && token) {
		return <ClientOnly>{cta || null}</ClientOnly>;
	}

	return hasAccess ? <ClientOnly>{cta}</ClientOnly> : <ClientOnly>{children}</ClientOnly>;
};

export { AccessCheck };
