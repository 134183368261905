"use client";

import type { Alignment, Placement } from "@floating-ui/react";
import {
	arrow,
	autoPlacement,
	autoUpdate,
	FloatingArrow,
	FloatingPortal,
	inline,
	offset,
	safePolygon,
	shift,
	useClick,
	useDismiss,
	useFloating,
	useFocus,
	useHover,
	useInteractions,
	useMergeRefs,
	useRole,
} from "@floating-ui/react";
import { cva, VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";

const tooltipVariants = cva(
	"max-w-xs z-1000 rounded bg-white text-left font-inter text-base font-normal text-[#26323d] shadow-lg [&>svg.arrow]:shadow-xl",
	{
		variants: {
			variant: {
				default: "tooltip-default [&>svg.arrow]:fill-white",
				overview: "tooltip-overview [&>svg.arrow]:fill-white",
				reference: "[&>svg.arrow]:fill-white",
				purple: "bg-primary px-2 py-1 text-sm text-white [&>svg.arrow]:fill-primary",
				regular: "p-3 text-sm [&>svg.arrow]:fill-white",
				premium:
					"bg-examine-yellow-premium text-examine-purple-700 [&>svg.arrow]:fill-examine-yellow-premium p-4",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	}
);

interface TooltipOptions {
	arrowEl?: any;
	initialOpen?: boolean;
	placement?: Placement;
	allowedPlacement?: Placement[];
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
	show?: boolean;
}

export function useTooltip({
	initialOpen = false,
	placement,
	allowedPlacement,
	open: controlledOpen,
	onOpenChange: setControlledOpen,
	show = true,
}: TooltipOptions = {}) {
	const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

	const arrowRef = React.useRef(null);
	const open = controlledOpen ?? uncontrolledOpen;
	const setOpen = setControlledOpen ?? setUncontrolledOpen;
	const [interactionHover, setInteractionHover] = React.useState(false);
	const [interactionClick, setInteractionClick] = React.useState(false);

	React.useEffect(() => {
		if (window.innerWidth > 767) {
			setInteractionHover(true);
			setInteractionClick(false);
		}
		if (window.innerWidth < 767) {
			setInteractionHover(false);
			setInteractionClick(true);
		}
	}, []);

	const allowedPlacements: Placement[] = placement
		? [placement]
		: allowedPlacement
		? allowedPlacement
		: ["top", "left", "right", "bottom"];
	const data = useFloating({
		placement,
		open,
		onOpenChange: setOpen,
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(12),
			shift({ padding: 5 }),
			autoPlacement({
				allowedPlacements,
			}),
			arrow({
				element: arrowRef.current,
			}),
			inline(),
		],
	});

	const context = data.context;

	const click = useClick(context, {
		enabled: controlledOpen == null && interactionClick,
	});
	const hover = useHover(context, {
		delay: {
			open: 300,
			close: 250,
		},
		mouseOnly: true,
		restMs: 150,
		enabled: controlledOpen == null && interactionHover,
		handleClose: safePolygon(),
	});
	const focus = useFocus(context, {
		enabled: controlledOpen == null,
	});
	const dismiss = useDismiss(context);
	const role = useRole(context, { role: "tooltip" });

	const interactions = useInteractions([click, hover, focus, dismiss, role]);

	return React.useMemo(
		() => ({
			open,
			setOpen,
			arrowRef,
			show,
			...interactions,
			...data,
		}),
		[open, setOpen, show, interactions, data]
	);
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = React.createContext<ContextType>(null);

export const useTooltipContext = () => {
	const context = React.useContext(TooltipContext);

	if (context == null) {
		throw new Error("Tooltip components must be wrapped in <Tooltip />");
	}

	return context;
};

export function Tooltip({ children, ...options }: { children: React.ReactNode } & TooltipOptions) {
	// This can accept any props as options, e.g. `placement`,
	// or other positioning options.
	const tooltip = useTooltip({ ...options });
	return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
}

export const TooltipTrigger = React.forwardRef<
	HTMLElement,
	React.HTMLProps<HTMLElement> & { asChild?: boolean; forceOpen?: boolean }
>(function TooltipTrigger({ children, asChild = false, forceOpen = false, ...props }, propRef) {
	// const arrowRef = React.useRef(null);
	const context = useTooltipContext();
	const childrenRef = (children as any).ref;
	const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);
	// context.open = forceOpen;
	if (!context.show) {
		return children;
	}

	// `asChild` allows the user to pass any element as the anchor
	if (asChild && React.isValidElement(children)) {
		return React.cloneElement(
			children,
			context.getReferenceProps({
				ref,
				...props,
				...children.props,
				"data-state": context.open || forceOpen ? "open" : "closed",
			})
		);
	}

	return (
		<>
			<button
				ref={ref}
				// The user can style the trigger based on the state
				data-state={context.open ? "open" : "closed"}
				{...context.getReferenceProps(props)}
			>
				{children}
			</button>
		</>
	);
});

const TooltipArrow = React.forwardRef(function TooltipArrow(props, ref) {
	const arrowEl = React.useRef(null);
	const context = useTooltipContext();

	React.useEffect(() => {
		if (arrowEl.current == null) {
			return;
		}
		context.arrowRef.current = arrowEl.current;
	}),
		[context, arrowEl];

	return (
		<FloatingArrow
			ref={useMergeRefs([arrowEl, ref])}
			context={context.context}
			tipRadius={1}
			className="arrow"
		/>
	);
});
interface TooltipContentProps extends React.HTMLProps<HTMLDivElement> {
	variant?: VariantProps<typeof tooltipVariants>["variant"];
	side?: Placement;
	align?: any;
}

export const TooltipContent = React.forwardRef<HTMLDivElement, TooltipContentProps>(
	function TooltipContent({ style, variant, side, align, className, ...props }, propRef) {
		const context = useTooltipContext();
		const ref = useMergeRefs([context.refs.setFloating, propRef]);

		if (!context.open) return null;

		return (
			<FloatingPortal>
				<div
					ref={ref}
					className={cn(tooltipVariants({ variant, className }))}
					style={{
						...context.floatingStyles,
						...style,
					}}
					{...context.getFloatingProps(props)}
				>
					{props.children}
					<TooltipArrow />
				</div>
			</FloatingPortal>
		);
	}
);
