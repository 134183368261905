"use client";

import Link, { LinkProps } from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import { useCallback } from "react";

import { useUI } from "@/components/ui/context";

interface LoginLinkProps extends Omit<LinkProps, "href"> {
	children?: React.ReactNode;
	className?: string;
	href?: string;
	menu?: boolean;
}

function LoginLink({ menu = false, children, className, href }: LoginLinkProps) {
	const pathname = usePathname();
	const searchParams = useSearchParams();

	const redirectUrl = `${pathname}${searchParams?.size ? `?${searchParams}` : ""}`;
	const loginUrl = `/login/?return_url=${encodeURIComponent(redirectUrl)}`;

	const { displayMenu, openMenu, closeMenu } = useUI();

	const handleClick = useCallback(() => {
		if (displayMenu) {
			closeMenu();
			document.body.classList.remove("overflow-hidden");
		} else if (menu) {
			openMenu("profile-menu");
		}
	}, [menu, displayMenu, openMenu, closeMenu]);

	if (pathname === "/login/") {
		return null;
	}

	// when `menu` is enabled, we open menu
	if (menu) {
		return (
			<div className={className} onClick={handleClick}>
				{children}
			</div>
		);
	}

	return (
		<Link
			href={href || loginUrl}
			className={className}
			onClick={() => {
				closeMenu();
				document.body.classList.remove("overflow-hidden");
			}}
			rel="nofollow"
		>
			{children}
		</Link>
	);
}

export { LoginLink };
