export const LEGACY_PLAN_VIEWS = {
	"loggedin-paid-monthly-user": "Monthly",
	"loggedin-paid-yearly-user": "Yearly",
	"loggedin-paid-lifetime-user": "Lifetime",
	"loggedin-paid-3-years-user": "3 Years",
};

export const PLAN_VIEWS = {
	...LEGACY_PLAN_VIEWS,
	monthly: "Monthly",
	yearly: "Yearly",
	lifetime: "Lifetime",
	"3-years": "3 Years",
};

export const ENTERPRISE_VIEWS = {
	enterprise: "Enterprise",
};

export const LEGACY_VIEWS = {
	"legacy-edb": "Examine Plus (a-z reference)",
	"legacy-sg": "Examine Plus (a-z reference)",
	"legacy-lifetime": "Lifetime",
};

export const PAID_VIEWS = {
	...PLAN_VIEWS,
	...ENTERPRISE_VIEWS,
	...LEGACY_VIEWS,
};

export const FREE_VIEWS = {
	free: "Free",
	null: "Free",
};

export const ALL_VIEWS = {
	...PAID_VIEWS,
	...FREE_VIEWS,
};

export const NO_SALE_PLANS = [
	"Enterprise",
	"Examine Plus (a-z reference)",
	"NERD - Lifetime",
	"Examine Personalized - Lifetime",
];
