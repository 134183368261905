"use client";

import { useSubscription } from "@lib/hooks/use-subscription";
import { getCookie } from "cookies-next";
import { usePathname, useSearchParams } from "next/navigation";
import * as React from "react";
import useSWR from "swr";

import { swrFetcher } from "@/lib/hooks/fetch-client";
import { useAccess, useLegacyAccess } from "@/lib/hooks/use-access";
import { useEarlybird } from "@/lib/hooks/use-earlybird";
import { useMounted } from "@/lib/hooks/use-mounted";
import { useSettings } from "@/lib/hooks/use-settings";
import { ALL_VIEWS, NO_SALE_PLANS as noSalePlans } from "@/lib/utils/views";

interface SaleWrapperProps {
	children?: React.ReactNode;
	cta?: React.ReactNode;
	earlybirdCta?: React.ReactNode;
	monthlyPaidCta?: React.ReactNode;
	yearlyPaidCta?: React.ReactNode;
	lifetimePaidCta?: React.ReactNode;
	threeYearsPaidCta?: React.ReactNode;
	fallback?: React.ReactNode;
}

export default function SaleWrapper({
	children,
	cta,
	earlybirdCta,
	monthlyPaidCta,
	yearlyPaidCta,
	lifetimePaidCta,
	threeYearsPaidCta,
	fallback = null,
}: SaleWrapperProps) {
	const searchParams = useSearchParams();
	const pathname = usePathname();
	const { settings, isLoading: isLoadingSettings } = useSettings();
	const mounted = useMounted();
	const token = getCookie("token");

	const { isEnterpriseUser } = useSubscription();
	const { data: subscription, isLoading: isLoadingSubscription } = useSWR(
		token ? ["/v1/subscription", token] : null,
		swrFetcher
	);

	const hasAccess = useAccess(["study_summaries", "examine_database", "supplement_guides"]);
	const isLegacyUser = useLegacyAccess();
	const isEarlybird = useEarlybird();
	const isSales = settings?.sale;

	const testView = searchParams.has("test_view") ? searchParams.get("test_view") : null;
	const plan = testView ? ALL_VIEWS[testView] : subscription?.plan?.name;
	const isPlus = pathname === "/plus/";

	const loading = React.useMemo(
		() => !mounted || isLoadingSubscription || isLoadingSettings,
		[isLoadingSettings, isLoadingSubscription, mounted]
	);

	React.useEffect(() => {
		if (!loading) {
			document.dispatchEvent(new Event("rm:rerun")); // re-run rightmessage after loading
		}
	}, [isLoadingSettings, isLoadingSubscription, loading, mounted]);

	if (loading) return fallback || cta;

	if (isEnterpriseUser) {
		return cta;
	}

	if (isEarlybird && earlybirdCta) {
		return earlybirdCta;
	}

	if (isSales && plan === "3 Years") {
		if (!threeYearsPaidCta) return cta || null;
		return threeYearsPaidCta;
	}

	if (isSales && (plan === "Lifetime" || plan === "Supplement Guides Main")) {
		if (!lifetimePaidCta) return cta || null;
		return lifetimePaidCta;
	}

	if (isSales && plan === "Yearly") {
		if (!yearlyPaidCta) return isPlus ? children : cta || null;
		return yearlyPaidCta;
	}

	if (isSales && plan === "Monthly") {
		if (!monthlyPaidCta) return isPlus ? children : cta || null;
		return monthlyPaidCta;
	}

	if (isSales && isLegacyUser) {
		return isPlus ? children : cta || null;
	}

	if (!isSales || noSalePlans.includes(plan) || ((hasAccess || isLegacyUser) && plan !== "Free")) {
		return cta || null;
	}

	return children;
}
