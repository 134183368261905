"use client";

import { cva, type VariantProps } from "class-variance-authority";
import { AlertCircle, AlertTriangle, CheckCircle2, Info, XCircle } from "lucide-react";
import * as React from "react";

import { cn } from "@/lib/utils";

import { buttonVariants } from "./button";

export type AlertDismissType = "default" | "custom";

export type AlertVariantProps = VariantProps<typeof alertVariants>;

type AlertProps = {
	hasIcon?: boolean;
	allowDismiss?: boolean;
	dismissType?: AlertDismissType;
	id?: string;
} & React.HTMLAttributes<HTMLDivElement> &
	VariantProps<typeof alertVariants>;

type AlertContextType = {
	id: string;
	show: boolean;
	dismissType: AlertDismissType;
	close: () => void;
} | null;

const AlertContext = React.createContext<AlertContextType>(null);

export const useAlertContext = () => {
	const context = React.useContext(AlertContext);

	if (context == null) {
		throw new Error("Alert components must be wrapped in <Alert />");
	}

	return context;
};

const AlertIcons = {
	notification: Info,
	info: Info,
	"new-info": Info,
	warning: AlertTriangle,
	success: CheckCircle2,
	error: AlertCircle,
} as const;

const alertVariants = cva(
	"alert-content group relative rounded-lg border-l-8 py-4 pl-7 pr-9 text-gray-900 md:text-lg md:leading-6 xl:leading-7",
	{
		variants: {
			variant: {
				default:
					"text-examine-green-900 border-examine-green-400 [&_.lucide-xcircle]:fill-examine-green-400 [&_.lucide-xcircle]:stroke-white",
				general:
					"alert-general bg-examine-purple-100 border-examine-purple-300 [&_.lucide-xcircle]:fill-examine-purple-300 [&_.lucide-xcircle]:stroke-examine-purple-100",
				summary:
					"alert-summary border-examine-purple-500 bg-examine-purple-100 [&_.lucide-xcircle]:fill-examine-purple-500 [&_.lucide-xcircle]:stroke-examine-purple-100",
				quotation:
					"alert-quotation border-l-4 border-info py-2 pl-4 text-primary xl:pl-6 xl:text-xl [&_.lucide-xcircle]:fill-info [&_.lucide-xcircle]:stroke-white",
				digging:
					"alert-digging border-examine-green-400 bg-[#ebfcff] [&_.lucide-xcircle]:fill-examine-green-400 [&_.lucide-xcircle]:stroke-[#ebfcff]",
				dd: "alert-digging border-examine-green-400 bg-[#ebfcff] [&_.lucide-xcircle]:fill-examine-green-400 [&_.lucide-xcircle]:stroke-[#ebfcff]",
				caution:
					"alert-caution bg-caution-bg border-caution-border [&_.lucide-xcircle]:fill-caution-border [&_.lucide-xcircle]:stroke-caution-bg",
				tip: "alert-tip bg-[#ffe6b9] border-[#f2ce72] text-[#222] [&_.lucide-xcircle]:fill-[#f2ce72] [&_.lucide-xcircle]:stroke-[#ffe6b9]",
				premium:
					"alert-premium bg-main border-[#fde047] [&_.lucide-xcircle]:fill-[#fde047] [&_.lucide-xcircle]:stroke-main",
				warning:
					"alert-warning border-[#f2ce72] bg-[#F1CD7833] [&_.lucide-xcircle]:fill-[#f2ce72] [&_.lucide-xcircle]:stroke-[#fff2be]",
				info: "alert-info border-examine-purple-500 bg-examine-purple-100 [&_.lucide-xcircle]:fill-examine-purple-500 [&_.lucide-xcircle]:stroke-examine-purple-100",
				success: "alert-success bg-examine-teal-100 border-examine-teal-500 text-examine-teal-900",
			},
			style: {
				default: "",
				// alternative style: https://www.figma.com/file/p0x0PyuO0SmY0uTjBpG3CA/Examine-Design-Files---Master-(updated---2024-01)?type=design&node-id=1746-65466&mode=design&t=BBVgkttblwOka0qv-4
				alt: "flex items-start p-4 text-sm lg:text-base rounded border text-[--text-color] border-[--border-color] bg-[--bg-color]",
			},
		},
		compoundVariants: [
			{
				variant: "info",
				style: "alt",
				class: "[--text-color:#084298] [--border-color:#b6d4fe] [--bg-color:#cfe2ff]",
			},
			{
				variant: "warning",
				style: "alt",
				class: "[--text-color:#664d03] [--border-color:#ffecb5] [--bg-color:#fff3cd]",
			},
			{
				variant: "success",
				style: "alt",
				class: "[--text-color:#0f5132] [--border-color:#badbcc] [--bg-color:#d1e7dd]",
			},
			{
				variant: "caution",
				style: "alt",
				class: "[--text-color:#842029] [--border-color:#f5c2c7] [--bg-color:#f8d7da]",
			},
		],
		defaultVariants: {
			variant: "default",
			style: "default",
		},
	}
);

const shouldShowAlert = (id: string) => {
	if (!id) {
		return true;
	}

	const seen = localStorage.getItem(id);
	if (seen) {
		return false;
	}
	return true;
};

const Alert = ({
	className,
	variant,
	style,
	hasIcon,
	children,
	allowDismiss,
	dismissType = "default",
	id,
	...props
}: AlertProps) => {
	const [show, setShow] = React.useState(false);

	const Icon = React.useMemo(() => AlertIcons[variant ?? "new-info"], [variant]);

	const showDefaultDismissButton = React.useMemo(
		() => allowDismiss && dismissType === "default",
		[allowDismiss, dismissType]
	);

	React.useEffect(() => {
		setShow(shouldShowAlert(id));
	}, [id]);

	const close = () => {
		if (id) {
			localStorage.setItem(id, "seen");
			window.dispatchEvent(new Event("storage"));
		}
		setShow(false);
	};

	return (
		<AlertContext.Provider value={{ id, show, dismissType, close }}>
			{show && (
				<div
					role="alert"
					className={cn(alertVariants({ variant, style }), className)}
					id={id}
					{...props}
				>
					{hasIcon && (
						<span className="mr-2">
							<Icon className="size-5 shrink-0 fill-current stroke-[var(--bg-color)]" />
						</span>
					)}
					{children}
					{showDefaultDismissButton && <AlertDismissButton />}
				</div>
			)}
		</AlertContext.Provider>
	);
};
Alert.displayName = "Alert";

const AlertTitle = ({
	className,
	as: Comp = "h5",
	...props
}: React.HTMLAttributes<HTMLHeadingElement> & { as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" }) => (
	<Comp
		className={cn(
			"my-2 text-left font-medium group-[.alert-caution]:text-caution-text group-[.alert-digging]:text-primary group-[.alert-summary]:text-primary group-[.alert-tip]:text-[#a04300]",
			className
		)}
		{...props}
	/>
);
AlertTitle.displayName = "AlertTitle";

const AlertDescription = (props: React.HTMLAttributes<HTMLDivElement>) => <div {...props} />;
AlertDescription.displayName = "AlertDescription";

type AlertDismissButtonProps = React.HTMLAttributes<HTMLButtonElement>;

const AlertDismissButton = ({ children, ...props }: AlertDismissButtonProps) => {
	const { dismissType, close } = useAlertContext();

	const handleClickDismissButton = React.useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			event.preventDefault();
			close();
		},
		[close]
	);

	return (
		<button
			{...props}
			onClick={handleClickDismissButton}
			className={cn(
				props.className,
				dismissType === "default"
					? "absolute right-2 top-2"
					: buttonVariants({ variant: "primary" })
			)}
		>
			{dismissType === "default" ? <XCircle className="size-5 hover:opacity-70" /> : children}
		</button>
	);
};

AlertDismissButton.displayName = "AlertDismissButton";

export { Alert, AlertTitle, AlertDescription, AlertDismissButton };
